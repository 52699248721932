<template>
  <fwb-toast-provider :transition="transition" class="toast-provider relative">
    <div v-if="loading" class="loading">
      <LoadingMain></LoadingMain>
    </div>
    <div v-else class="main">
      <div class="flex bg-gray-50 dark:bg-surface-900">
        <HeaderPage
          :isSidebar="isSidebar"
          @toggleSidebar="toggleSidebar()"
        ></HeaderPage>
        <div
          class="w-full overflow-y-hidden flex bg-gray-50 dark:bg-surface-900"
        >
          <SliderPage class="h-screen dark:bg-surface-900"></SliderPage>
          <div
            id="main-conetnt"
            class="relative overflow-y-auto overflow-x-hidden h-screen px-2 pt-20 w-full bg-gray-50 lg:ml-64 dark:bg-surface-900"
          >
            <router-view />
            <fwb-footer
              class="mt-4 rounded-sm dark:bg-surface-900"
              footer-type="logo"
            >
              <div class="w-full max-w-screen-xl mx-auto md:py-2">
                <fwb-footer-copyright href="/" by="JOJO™" />
              </div>
            </fwb-footer>
          </div>
        </div>
      </div>
    </div>
  </fwb-toast-provider>
</template>

<script>
import HeaderPage from "./components/widgets/HeaderWidget.vue";
import SliderPage from "./components/widgets/SliderWidget.vue";
import LoadingMain from "./components/widgets/LoadingMain.vue";
import { FwbToastProvider, FwbFooter, FwbFooterCopyright } from "flowbite-vue";
import { ref, onMounted, getCurrentInstance } from "vue";
export default {
  components: {
    LoadingMain,
    HeaderPage,
    SliderPage,
    FwbToastProvider,
    FwbFooter,
    FwbFooterCopyright,
  },
  setup() {
    const sidebar = ref(null);
    const isSidebar = ref(true);
    const transition = ref("slide-right");
    const { proxy } = getCurrentInstance();
    const loading = ref(true);

    function loadTheme() {
      if (
        localStorage.getItem("color-theme") === "dark" ||
        !("color-theme" in localStorage)
      ) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }

    onMounted(() => {
      document.title = proxy.COMMON.title;
      loadTheme();
      loading.value = false;
    });

    return {
      loading,
      sidebar,
      isSidebar,
      transition,
    };
  },
  methods: {
    toggleSidebar() {
      let aside = document.getElementById("aside");
      this.isSidebar
        ? aside.classList.remove("hidden")
        : aside.classList.add("hidden");
      this.isSidebar = !this.isSidebar;
    },
  },
};
</script>

<style>
body {
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

@media (min-width: 950px) {
  body {
    overflow: hidden;
  }
}

@-moz-document url-prefix() {
  div {
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(129, 120, 120, 0.2);
  background: #eef4fa;
}

.dark ::-webkit-scrollbar-thumb {
  background: #222223;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 4px;
  background: transparent;
}

textarea {
  scrollbar-width: thin;
  scrollbar-color: #c8bebe #eef4fa;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark .loading {
  background-color: rgba(27, 30, 35, 0.3);
}

.loading {
  background-color: rgb(31, 41, 55, 0.1);
}

:is(.dark .dark\:bg-surface-900),
:is(.dark .dark\:bg-gray-800) {
  background-color: rgb(3, 2, 12, 1);
}

:is(.dark .dark\:bg-secondary-100) {
  background-color: rgb(220, 218, 250, 1);
}

.dark .h-modal .relative .shadow {
  background-color: #0b0b16;
}

.toast-provider > :nth-child(2) {
  position: absolute;
  top: 2%;
  right: 1%;
}

.slider-content a.active:hover {
  background-color: white;
}

.slider-content a.active {
  background-color: rgb(220, 218, 250, 1);
  color: #222223;
}

.slider-content .search {
  margin-bottom: 40px;
}
.slider-content a.active svg {
  color: #222223;
}

.tool-card,
.slider-card,
.new-card {
  max-height: 24rem;
  aspect-ratio: 16 / 12;
}

.slider-card {
  width: 50%;
  width: 50%;
}

.tool-card,
.new-card {
  width: 25%;
}

.new-list {
  max-height: 21rem;
}

.video-info {
  position: absolute;
  left: 20px;
  bottom: 30px;
  width: 70%;
}

.content-rigth {
  width: 32%;
}

.dark .dark\:border-0 {
  border-width: 0;
}

.dark .title-icon svg {
  color: white;
}

.video-item-card {
  transform: translateY(-4px) scale(1);
  transition: all 0.2s ease-in-out;
}

.video-item-card:hover {
  transform: translateY(-4px) scale(1.05);
  transition: all 0.2s ease-in-out;
}

.video-item-card-image,
.like-item-card img {
  aspect-ratio: 12/16;
  height: 90%;
  max-height: 224px;
}

.like-item-card img,
.watch-item-card img {
  height: 100%;
}

.video-item-card-heard {
  border-top-right-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
  background: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.content-like-list {
  margin-top: -5px;
  max-height: 500px;
}

.watch-list,
.tag-list {
  width: 45%;
}

.art-video-player {
  z-index: 10;
}

.art-player {
  width: 100%;
  aspect-ratio: 16/9;
}

.watch-list-content {
  max-height: 495px;
  overflow-y: auto;
}

.detail-sketch,
.video-item-card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.video-item-card-title {
  -webkit-line-clamp: 1;
}
.speed-item {
  word-break: keep-all;
}
.dark .speed-item {
  background-color: #24253e;
}

.tv-type-list h1.active,
.movie-type-list h1.active {
  color: #3b82f6;
}

.speed-item.active {
  background-color: #3b82f6;
  color: white;
}

.bg-opacity-50 {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media (max-width: 800px) {
  .h-modal {
    height: 95vh;
  }
  #main-conetnt button .mr-2 {
    margin-right: 0;
  }
  .show-sm {
    display: table-cell;
  }
  .slider-card {
    width: 100%;
    height: 16em;
  }
  .search-input {
    min-width: 80%;
  }
  .video-item-card-image,
  .like-item-card img {
    max-height: 180px;
  }
}

@media (min-width: 800px) {
  .slider-content a {
    height: 68px;
    width: 68px;
  }

  .slider-content a.active span {
    display: none;
  }
  .slider-content a.active svg {
    margin-top: 10px;
  }
}

.new-list img {
  aspect-ratio: 12/16;
}

.\!rounded-full {
  border-radius: 9999px !important;
}
.vue-apexcharts text {
  fill: black;
}

.dark .vue-apexcharts text {
  fill: white;
}

.rounded-sm textarea.rounded-lg,
.content .rounded-sm {
  border-radius: 0.125rem;
}

.v-card {
  min-width: 100%;
}

.video-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.show-card-list img {
  aspect-ratio: 12/16;
}
@media print {
  nav {
    display: none;
  }
  #main-content {
    margin-top: -5rem;
  }
  img {
    aspect-ratio: 12/16;
  }
}

.slider-content .ml-3 {
  margin-left: 0;
}

@keyframes shake {
  0% {
    transform: scale(1);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1);
  }
  20%,
  40%,
  60%,
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.art-controls svg:hover {
  animation: shake 1s;
}
</style>


