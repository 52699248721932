import "flowbite/dist/flowbite.min.css";
import { createApp } from 'vue';
import App from './App.vue';
import global from './components/utils/CommonSetting.vue';
import router from './router';




const app = createApp(App);
app.config.globalProperties.COMMON = global;
app.use(router);
app.mount('#app');
