<template>
  <div class="load-page">
    <div
      class="loading overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full inset-0 h-modal dark:bg-gray-900 justify-center items-center flex"
    >
      <fwb-spinner size="12" />
    </div>
  </div>
</template>

<script>
import { FwbSpinner } from "flowbite-vue";
export default {
  components: {
    FwbSpinner,
  },
  setup() {},
};
</script>