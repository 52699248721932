<template>
  <div class="slider-content md:w-24">
    <fwb-sidebar
      id="aside"
      class="w-full absolute border-r border-gray-200 dark:border-0 lg:sticky top-0 left-0 bg-gray-50 dark:border-gray-700 z-20 flex-col flex-shrink-0 hidden min-h-screen pt-16 font-normal duration-75 lg:flex transition-width"
    >
      <fwb-sidebar-item class="flex-col search" link="/search">
        <template #icon>
          <svg
            class="w-8 h-8 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
            />
          </svg>
        </template>
        <template #default>搜索</template>
      </fwb-sidebar-item>
      <fwb-sidebar-item class="flex-col" link="/">
        <template #icon>
          <svg
            class="w-7 h-7 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M11.3 3.3a1 1 0 0 1 1.4 0l6 6 2 2a1 1 0 0 1-1.4 1.4l-.3-.3V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3c0 .6-.4 1-1 1H7a2 2 0 0 1-2-2v-6.6l-.3.3a1 1 0 0 1-1.4-1.4l2-2 6-6Z"
              clip-rule="evenodd"
            />
          </svg>
        </template>
        <template #default>主页</template>
      </fwb-sidebar-item>
      <fwb-sidebar-item class="flex-col" link="/movie">
        <template #icon>
          <svg
            class="w-7 h-7 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M19 3a2 2 0 0 1 2 2v2h-2V5.4L17.4 7h-2.8l2-2h-2.2l-2 2H9.6l2-2H9.4l-2 2H3V5c0-1.1.9-2 2-2h14ZM3 9v10c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V9H3Zm2-2.4L6.6 5H5v1.6ZM9.6 11a1 1 0 0 1 1 .1l4 3a1 1 0 0 1 0 1.6l-4 3A1 1 0 0 1 9 18v-6c0-.4.2-.7.6-.9Z"
              clip-rule="evenodd"
            />
          </svg>
        </template>
        <template #default>电影</template>
      </fwb-sidebar-item>
      <fwb-sidebar-item class="flex-col" link="/tv">
        <template #icon>
          <svg
            class="w-7 h-7 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="M9 8h10M9 12h10M9 16h10M5 8h0m0 4h0m0 4h0"
            />
          </svg>
        </template>
        <template #default>剧集</template>
      </fwb-sidebar-item>
      <fwb-sidebar-item class="flex-col" link="/app">
        <template #icon>
          <svg
            class="w-7 h-7 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M4.9 3C3.9 3 3 3.8 3 4.9V9c0 1 .8 1.9 1.9 1.9H9c1 0 1.9-.8 1.9-1.9V5c0-1-.8-1.9-1.9-1.9H5Zm10 0c-1 0-1.9.8-1.9 1.9V9c0 1 .8 1.9 1.9 1.9H19c1 0 1.9-.8 1.9-1.9V5c0-1-.8-1.9-1.9-1.9h-4Zm-10 10c-1 0-1.9.8-1.9 1.9V19c0 1 .8 1.9 1.9 1.9H9c1 0 1.9-.8 1.9-1.9v-4c0-1-.8-1.9-1.9-1.9H5ZM18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
              clip-rule="evenodd"
            />
          </svg>
        </template>
        <template #default>应用</template>
      </fwb-sidebar-item>
      <a
        :href="COMMON.helpUrl"
        target="_blank"
        class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group flex-col"
      >
        <svg
          class="w-7 h-7 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M3.6 4.5c.3-.3.8-.5 1.3-.5H19a1.9 1.9 0 0 1 2 1.9V15a1.9 1.9 0 0 1-1.9 1.9h-3.6l-2.7 3.6a1 1 0 0 1-1.6 0L8.5 17H4.9A1.9 1.9 0 0 1 3 15.1V6c0-.5.2-1 .6-1.4Zm4 3a1 1 0 1 0 0 2h8a1 1 0 0 0 0-2h-8Zm0 3.5a1 1 0 1 0 0 2H12a1 1 0 1 0 0-2H7.6Z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="flex-1 whitespace-nowrap ml-3">反馈</span></a
      >
      <a
        @click="emailModal = !emailModal"
        target="_blank"
        class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group flex-col"
      >
        <svg
          class="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M2 5.6V18c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V5.6l-.9.7-7.9 6a2 2 0 0 1-2.4 0l-8-6-.8-.7Z"
          />
          <path
            d="M20.7 4.1A2 2 0 0 0 20 4H4a2 2 0 0 0-.6.1l.7.6 7.9 6 7.9-6 .8-.6Z"
          />
        </svg>

        <span class="flex-1 whitespace-nowrap ml-3">邮箱</span></a
      >
    </fwb-sidebar>
    <div
      class="sticky z-20 bottom-0 left-0 justify-center border-r border-gray-200 dark:border-0 dark:border-gray-700 hidden w-full p-4 space-x-4 bg-gray-50 lg:flex dark:bg-gray-800"
    >
      <a
        @click="settingShow()"
        class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group flex-col"
      >
        <svg
          class="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M9.6 2.6A2 2 0 0 1 11 2h2a2 2 0 0 1 2 2l.5.3a2 2 0 0 1 2.9 0l1.4 1.3a2 2 0 0 1 0 2.9l.1.5h.1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2l-.3.5a2 2 0 0 1 0 2.9l-1.3 1.4a2 2 0 0 1-2.9 0l-.5.1v.1a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2l-.5-.3a2 2 0 0 1-2.9 0l-1.4-1.3a2 2 0 0 1 0-2.9l-.1-.5H4a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2l.3-.5a2 2 0 0 1 0-2.9l1.3-1.4a2 2 0 0 1 2.9 0l.5-.1V4c0-.5.2-1 .6-1.4ZM8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="flex-1 whitespace-nowrap ml-3">设置</span></a
      >
    </div>
    <fwb-modal
       size="sm"
      v-if="emailModal"
      @close="emailModal = !emailModal"
      @click:outside="emailModal = false"
    >
      <template #header>
        <div class="flex items-center text-lg text-gray-500 dark:text-gray-200">
          联系邮箱
        </div>
      </template>
      <template #body>
        <p class="text-base leading-relaxed text-gray-500 dark:text-gray-200">
          本站仅做学习演示使用，资源均网络自动采集，如有关视频侵犯了你的权益，请联系邮箱：2172934425#qq.com(#改为@)，本站将于24小时内删除！
        </p>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <fwb-button
            class="rounded-sm"
            @click="emailModal = !emailModal"
            color="blue"
          >
            确定
          </fwb-button>
        </div>
      </template>
    </fwb-modal>
  </div>
</template>

<script>
import {
  useToast,
  FwbSidebar,
  FwbSidebarItem,
  FwbButton,
  FwbModal,
} from "flowbite-vue";
import { ref } from "vue";
export default {
  components: {
    FwbSidebarItem,
    FwbSidebar,
    FwbButton,
    FwbModal,
  },
  setup() {
    const toast = useToast();
    const emailModal = ref(false);
    return {
      toast,
      emailModal,
    };
  },
  methods: {
    settingShow() {
      this.toast.add({
        type: "success",
        time: 3000,
        text: "暂未开发!",
      });
    },
  },
};
</script>

<style scoped>
aside a.pl-11 {
  padding-left: 2.5rem;
}
</style>