<template>
  <nav
    class="fixed z-30 w-full bg-gray-50 border-b border-gray-200 dark:border-0 dark:bg-surface-900 dark:border-gray-700"
  >
    <div class="px-3 py-3 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-start">
          <button
            @click="toggleSidebar"
            id="toggleSidebarMobile"
            aria-expanded="true"
            aria-controls="sidebar"
            class="p-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <svg
              v-if="isSidebar"
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg
              v-else
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <router-link to="/" class="flex ml-2 md:mr-24 items-center">
            <img src="/images/logo.svg" class="h-7 mr-2" alt="Logo" />
            <span
              class="self-center text-xl font-semibold sm:text-xl whitespace-nowrap dark:text-white"
              >{{ COMMON.title }}</span
            >
          </router-link>
        </div>
        <div class="flex items-center">
          <button
            type="button"
            @click="isShowModal = !isShowModal"
            class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
              ></path>
            </svg>
          </button>
          <button
            type="button"
            class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
          >
            <svg
              @click="toggleTheme('dark')"
              :class="[color_theme == 'dark' ? 'hidden' : '', 'w-5', 'h-5']"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
              ></path>
            </svg>
            <svg
              @click="toggleTheme('light')"
              :class="[color_theme == 'light' ? 'hidden' : '', 'w-5', 'h-5']"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                fill-rule="evenodd"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <button
            type="button"
            class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
          >
            <router-link to="/search" class="">
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                ></path>
              </svg>
            </router-link>
          </button>

          <div class="flex items-center ml-3">
            <fwb-modal
              size="sm"
              v-if="isShowModal"
              @close="isShowModal = !isShowModal"
            >
              <template #header>
                <div class="flex items-center text-lg dark:text-white">
                  消息
                </div>
              </template>
              <template #body>
                <div
                  class="z-50 text-base list-none bg-white divide-y divide-gray-100 rounded dark:bg-gray-800 dark:divide-gray-600"
                >
                  <ul class="py-1" role="none">
                    <li>
                      <div
                        class="block px-2 py-2 text-lg text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                      >
                        暂无消息
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </fwb-modal>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { onMounted, ref } from "vue";
import { FwbModal } from "flowbite-vue";
export default {
  components: {
    FwbModal,
  },
  props: {
    isSidebar: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const color_theme = ref(null);
    const isShowModal = ref(false);
    const isMsgModal = ref(false);
    function loadTheme() {
      if (
        localStorage.getItem("color-theme") === "dark" ||
        !("color-theme" in localStorage)
      ) {
        document.documentElement.classList.add("dark");
        color_theme.value = "dark";
      } else {
        document.documentElement.classList.remove("dark");
        color_theme.value = "light";
      }
    }

    onMounted(() => {
      loadTheme();
    });
    const theme = async () => {
      loadTheme();
    };
    return {
      color_theme,
      isShowModal,
      isMsgModal,
      theme,
    };
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    toggleTheme(theme) {
      localStorage.setItem("color-theme", theme);
      this.color_theme = theme;
      this.theme();
    },
  },
};
</script>
<style scoped>
</style>

