import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('../components/pages/HomePage.vue')
    },
    {
        path: '/search',
        name: 'SearchPage',
        component: () => import('../components/pages/SearchPage.vue')
    },
    {
        path: '/movie',
        name: 'MoviePage',
        component: () => import('../components/pages/MoviePage.vue')
    },
    {
        path: '/tv',
        name: 'TvPage',
        component: () => import('../components/pages/TvPage.vue')
    },
    {
        path: '/app',
        name: 'AppPage',
        component: () => import('../components/pages/AppPage.vue')
    },
    {
        path: '/comment',
        name: 'CommentPage',
        component: () => import('../components/pages/CommentPage.vue')
    },
    {
        path: "/watch/:id",
        name: "WatchPage",
        component: () => import('../components/pages/WatchPage.vue')
    },
    {
        path: "/list/:video_type",
        name: "ListPage",
        component: () => import('../components/pages/ListPage.vue')
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
    scrollBehavior() {
        let main_conetnt = document.getElementById("main-conetnt");
        if (main_conetnt != null) {
            main_conetnt.scrollTo(0,0);
        }
    }
});
export default router