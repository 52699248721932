<script type="text/javascript">
const title = "JOJO";
const apiUrl = "https://api.jiohub.top";
const helpUrl = "https://support.qq.com/product/603699";

const tv_types = [
  {
    title: "最新",
    back_image:
      "https://wework.qpic.cn/wwpic3az/333507_-RxQ1LgyTr2PsKO_1707298196/0",
  },
  {
    title: "美剧",
    back_image:
      "https://wework.qpic.cn/wwpic3az/934648_cHCpE-kpTni8NRp_1707298309/0",
  },
  {
    title: "日剧",
    back_image:
      "https://wework.qpic.cn/wwpic3az/880716_DoAw9E5LQTS33Mx_1707298323/0",
  },
  {
    title: "韩剧",
    back_image:
      "https://wework.qpic.cn/wwpic3az/539421_t6dxH1ZaQO29xHz_1707298337/0",
  },
  {
    title: "动漫",
    back_image:
      "https://wework.qpic.cn/wwpic3az/172818_Qo9QTzYLQJuU0iP_1707298365/0",
  },
  {
    title: "国产",
    back_image:
      "https://wework.qpic.cn/wwpic3az/336208_XpeusLxlTCiDt_i_1707298384/0",
  },
];
const movie_tags = [
  {
    title: "全部",
    back_image:
      "https://wework.qpic.cn/wwpic3az/110692_WlasEhw-RsGNBkF_1707298512/0",
  },
  {
    title: "喜剧",
    back_image:
      "https://wework.qpic.cn/wwpic3az/559325_6osLBWriS2OWZoH_1707298526/0",
  },
  {
    title: "动作",
    back_image:
      "https://wework.qpic.cn/wwpic3az/187333_z9GG-kf4QhWtZ9w_1707298538/0",
  },
  {
    title: "爱情",
    back_image:
      "https://wework.qpic.cn/wwpic3az/421745_u2MUn9fTQguP-l8_1707298549/0",
  },
  {
    title: "科幻",
    back_image:
      "https://wework.qpic.cn/wwpic3az/386774_7-2SfaOBQ0y9f3Z_1707298563/0",
  },
  {
    title: "悬疑",
    back_image:
      "https://wework.qpic.cn/wwpic3az/158093_UEtnYGicSm6rdUu_1707298593/0",
  },
];
const devices = {
  android: {
    ligth:
      "https://wework.qpic.cn/wwpic3az/187164_OzxJoMt7Sl2dBKe_1707299123/0",
    dark: "https://wework.qpic.cn/wwpic3az/791326_vdgGtTaLRfKzlqN_1707299140/0",
  },
  windows: {
    ligth:
      "https://wework.qpic.cn/wwpic3az/641934_fkY05u_hQAST8CD_1707299154/0",
    dark: "https://wework.qpic.cn/wwpic3az/398924_1C6PlhTBQlebQZA_1707299167/0",
  },
};

function formatAsDate(time) {
  let date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function getScoreInt(score) {
  if (score.length === 0) {
    return 0;
  }
  let number = parseInt(score) / 2;
  return number;
}

function getSpeed(data) {
  if (data.video_type === "电影") {
    return "电影";
  } else {
    let speed = data.url_content.split("\n").length;
    let newspeed = speed < 10 ? "0" + speed : speed;
    let speedData = "至" + newspeed + "集";
    return speedData;
  }
}

function chunkUrl(url) {
  let dataUrl = url;
  if (url.indexOf("ffzy") !== -1) {
    dataUrl = `https://oss.jiohub.top/v1/api/file/hls/proxy/${btoa(url)}.m3u8`;
  }
  return dataUrl;
}

function loginOut() {
  localStorage.removeItem("Authorization");
  window.location.reload();
}

export default {
  title,
  helpUrl,
  tv_types,
  movie_tags,
  devices,
  apiUrl,
  formatAsDate,
  loginOut,
  getScoreInt,
  getSpeed,
  chunkUrl,
};
</script>